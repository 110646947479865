// src/i18n.js
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'nl', // Set the default locale
    fallbackLocale: 'nl', // Set the fallback locale
    messages: {
        // Your language messages go here
        en: {
            // English translations
            Bedrijfspagina: 'Companypage',

            account_aanmaken: "Sign up",
            signup: 'Sign up',
            choose_lang: 'Choose language',

            emailadres: 'Email Address',
            password: 'Password',
            lost_password: "Lost password",

            component: {
                loggedOutOverview: {
                    title: 'Want to join our activities?',
                    content: 'Are you new here? Then create an account first. Have you been here before and already have an account? Then log in and see all the activities right away.',

                    next_activity: 'Upcoming activity(s)',
                },
            },

            views: {
                auth: {

                    registerconfirm: {
                        "termsandconditions": "I agree with the",
                        "generalconditions": "terms and conditions",
                        "title": "Verify your account",
                        expired_title: 'The link is invalid.',
                        expired_text1: 'The link for signing up for your account is invalid.',
                        expired_text2: 'Please try again.',
                        "content": "Welcome! Create an account with your work details.",
                        "name_l": "First name",
                        "name_ph": "Enter your first name",
                        "lastname_l": "Last name",
                        "lastname_ph": "Enter your last name",
                        "email_l": "Email addres",

                        "email_ph": "Enter your email",

                        "password_l": "Password",
                        "password_ph": "Choose a password",
                        "password_repeat_l": "Repeat password",
                        "password_repeat_ph": "Repeat password",
                        "terms": "I agree with the terms and conditions",
                        "verify_acc": "Verify account",
                        "privacy_settings": "Privacy Settings",
                        "check1": "I consent to my account data being used by the Fitte Medewerker. This allows the Fitte Medewerker to provide the best possible support.",
                        "check2": "I give permission to the Fitte Medewerker to contact me in case of direct questions. This helps us to respond quickly and efficiently to your needs.",
                        "submit_btn": "Create Account",
                        "sub_content": "Your data is safe with us and will only be used internally. We do not share your personal information with your employer or others."
                    },

                    login: {
                        inloggen: 'Login',
                        content: 'Welcome back! Log in to get started right away.',
                        email_placeholder: 'Enter your email address',
                        password_placeholder: 'Enter your password',
                    },
                    newpassword: {
                        expired_token_1: 'The link for changing your password has expired.',
                        expired_token_2: 'This is because the link is valid for a maximum of 12 hours.',
                        expired_token_3: 'Please try again.',
                        expired_btn: 'Request password',
                        title: 'Recover password',
                        content: 'The link for changing your password has expired. This is because the link is valid for a maximum of 15 minutes. Please try again.',
                        save_btn: 'Save password',
                        back_to_login_btn: 'Back to login',
                    },
                    resetpassword: {
                        content: 'Enter your email and we will send a link to reset your password.',
                        email_placeholder: 'Enter your email address',
                        submit_btn: 'Send',
                        back_to_login_btn: 'Back to login',
                        request_send_title: 'We sent you an email!',

                        request_send_content1: ' An email with a unique link in it to create a new password was sent to',
                        request_send_content2: 'The link in the email is valid for a maximum of 15 minutes. Please check your spam folder if necessary before trying again.',

                        toast: {
                            no_account_found: 'There is no account at this email address!',
                            error_changing_pass: "Something went wrong while requesting a new password!",
                        },


                    },
                    register: {
                        title: 'Create Account',
                        content: 'Unlock exclusive activities by signing up now',
                        name_l: 'Name',
                        name_ph: 'Enter your firstname',
                        lastname_l: 'Lastname',
                        lastname_ph: 'Enter your lastname',
                        email_l: 'Email adress',
                        email_ph: 'Enter your email address',
                        terms: 'I agree to the Terms and Conditions',
                        submit_btn: ' Create account',
                        sub_content: 'Your information is safe with us and will only be used internally. We do not share your personal information with your employer or others.',

                    },
                },
                account: {
                    overview: {
                        page_title: 'Edit your details here',
                        preferences_edit_title: 'Edit your preferences here',
                        language: 'Language',
                        firstname_l: 'First name',
                        firstname_ph: 'First name',
                        surname_l: 'Lastname',
                        surname_ph: 'Lastname',
                        email_l: 'Email address',
                        email_ph: 'Email address',
                        password_title: 'Change password',
                        current_password_l: 'Current password',
                        current_password_ph: 'Enter your password here',
                        new_password_l: 'New password',
                        new_password_ph: 'Enter your new password here',
                        repeat_new_password_l: 'Repeat new password',
                        repeat_new_password_ph: 'Repeat new password',
                        cancel_btn: 'Cancel',
                        save_btn: 'Save',
                        profile_pic_title: 'Profile picture',
                        upload_pic_l: 'Upload picture (JPEG, PNG, WEBP), max 2MB',
                        user_data_title: 'Basic details',
                        change_btn_l: 'Replace',
                        edit_btn_l: 'Edit your information',
                        name_title: 'Name',
                        email_title: 'Email address',
                        password_l: 'Password',
                        notification_title: 'Notifications',
                        preferences_title: 'Preferences',
                        email_reminders_title: 'Send me a reminder',
                        email_review_title: 'I want to post a review afterwards',
                        email_lastspots_title: 'Send me a last spots reminder',

                    },
                    template: {
                        toast_settings_message: 'Notification settings adjusted successfully.',
                        toast_error_401: 'You do not have access.',
                        toast_error_403: 'Inactive user!',
                        toast_error_default: 'Something went wrong.',
                        toast_succes_user_edit: 'User successfully edited',
                        toast_error_user_login_401: 'Incorrect email address and/or password!',
                        toast_error_user_login_403: 'Inactive user!',
                        toast_error_user_login_default: 'Something went wrong with the login',
                        toast_changepass_error_email: 'There is no account at this email address!',
                        toast_changepass_error_password: 'Something went wrong while requesting a new password!',
                    },
                },
                contact: {
                    overview: {
                        page_title: 'Frequently asked questions and contact',
                        page_text: "Is everything clear? Super! Still have questions? Check this list first for quick answers. Can't find what you need? No worries, reach out to us and we’ll answer all your questions!",
                    },
                },
                dashboard: {
                    page_title: 'Today is a good day to smile, do not forget that! 😁',
                    company_activity_title: 'Planned activities',
                    enroll_title: 'Upcoming activity: ',
                    company_activity_signups: 'Entries',
                    company_activity_page_l: 'To activities page',
                    company_signups_l: 'Viewing entries',
                    company_download_signups_l: 'Download entries',
                    company_activity_text: 'No activities are planned at this time.',
                    employee_activity_title: 'Activities you can still sign up for',
                    employee_activity_text: 'There are no activities available to sign up for at this time.',
                    employee_signup_activity_title: 'Activities you have signed up for',
                    employee_signup_activity_text: 'There are no activities you have signed up for.',
                    activity_time_l: 'I want to change something',
                    activity_participants_l: 'Who is participating?',
                    activity_signout_l: 'I want to unsubscribe',
                    activity_no_signup_l: 'No applications found yet. Discover and sign up for exciting activities!',
                    activity_signup_l: 'To activities',
                    activity_greeting_morning: 'Good morning',
                    activity_greeting_afternoon: 'Good afternoon',
                    activity_greeting_evening: 'Good evening',
                },
                template404: {
                    topbar_title: 'This page does not exist',
                },
                template: {
                    enroll_update_succes_edited: 'Successfully adjusted',
                    enroll_update_cancel_succes: 'Successfully signed off',
                    enroll_update_cancel_text: 'Cancellation period has ended!',
                    enroll_update_cancel_error: 'Something went wrong with signing out for the activity',
                },
                companypages: {
                    template: {
                        register_enroll_succes: 'Successfully signed up',
                        register_enroll_error: 'Something went wrong with signing up.',
                        register_enroll_error_catch: 'Something went wrong with signing up.',
                    },
                    overview: {
                        overview_pick_lang: 'Choose your language',
                        overview_activity_title: 'Upcoming activity(s)',
                        overview_btn_register: 'Read more',
                        overview_other_activity_title: 'All activity(ies)',
                    },
                },
                activities: {
                    template: {
                        register_enroll_succes: 'Successfully signed up',
                        register_enroll_error: 'Something went wrong with signing up.',
                        register_enroll_error_catch: 'Something went wrong with signing up.',
                    },
                    registrations: {
                        registrations_btn_download: 'Download Entries',
                        registrations_title: 'Laughter workshop: put your laughing muscles to work',
                        registrations_company_l: 'Choose company page',
                        registrations_location_l: 'Select branch(s)',
                        registrations_deal_l: 'Deal',
                        registrations_company_ph: 'Select a business page',
                        registrations_location_ph: 'Choose desired branch',
                        registrations_deal_ph: 'Select a deal',
                        registrations_btn_cancel: 'Cancel',
                        registrations_btn_connect: 'Connect',
                    },
                    view: {
                        view_btn_register: 'Sign up',
                        view_text_place_avail: 'There is a limited number of spots, be quick.',
                        view_l_date: 'Date',
                        view_l_date_till: 'Till',
                        view_l_date_time: 'Time',
                        view_l_location: 'Location(s)',
                        view_l_specialists: 'Specialist(s)',
                        view_l_watch_out: 'WATCH OUT:',
                        view_text_watch_out: 'Do you want to change something in your registration? Then choose Adjust. Do you want to unsubscribe? We ask that you do this at least ',
                        view_text_watch_out_2: 'hours in advance.',
                        view_l_need: 'Necessities',
                        view_l_expected: 'What to expect',
                    },
                },

            },

            components: {
                "changeActivityDialog": {
                    "dialog_title": "What would you like to do?",
                    "dialog_text": "For cancellations or changes, we kindly ask you to do this at least 48 hours in advance.",
                    "dialog_btn_cancel": "Sign out",
                    "dialog_btn_edit": "Change time slot",
                },
                "changeTimeDialog": {
                    "dialog_title": "Step 1/2",
                    "dialog_text": "Nice of you to sign up for:",
                    "dialog_date_l": "Choose day",
                    "dialog_date_ph_title": "Select day",
                    "dialog_date_ph_text": "Enter date",
                    "dialog_selected_date_l": "Selected day",
                    "dialog_cancel_btn": "Cancel",
                    "dialog_next_btn": "Continue",
                    "dialog_stepper_title": "Step 2/2",
                    "dialog_stepper_text": "Nice of you to sign up for:",
                    "dialog_stepper_l": "Available times",
                    "dialog_prev_btn": "Previous",
                    "dialog_save_btn": "Save changes",
                },
                "registerActivityDialog": {
                    "dialog_title": "Step 1/4",
                    "dialog_title_group_1": "Step 1/3",
                    "dialog_title_group_2": "Step 2/3",
                    "dialog_title_group_3": "Step 3/3",
                    "dialog_text": "Nice of you to sign up for:",
                    "dialog_date_l": "Choose day",
                    "dialog_date_ph_title": "Select day",
                    "dialog_date_ph_text": "Enter date",
                    "dialog_selected_date_l": "Selected days",
                    "dialog_prev_btn": "Previous",
                    "dialog_next_btn": "Continue",
                    "dialog_stepper_title": "Step 2/4",
                    "dialog_stepper_text": "Nice of you to sign up for:",
                    "dialog_stepper_settings_l": "Choose your settings for:",
                    "dialog_stepper_branche_l": "Choose your location",
                    "dialog_stepper_branche_ph": "Select a location",
                    "dialog_stepper_specialist_l": "Choose your specialist",
                    "dialog_stepper_specialist_ph": "Select a specialist",
                    "dialog_stepper_title_3": "Step 3/4",
                    "dialog_stepper_title_4": "Step 4/4",
                    "dialog_stepper_title_4_l": "Comments",
                    "dialog_stepper_title_4_text_ph": "Is there anything else you would like us to know? For example, do you have an injury, or do we need to take something else into account? Tell us about it here!",
                    "dialog_stepper_title_4_text": "Your details are safe with us and are only used internally. We do not share your personal information with your employer or others.",
                    "dialog_stepper_title_4_l_invite": "Invite your favorite colleague(s) to participate",
                    "dialog_stepper_title_4_l_email": "Colleagues' email",
                    dialog_date_choose: 'Select your desired day(s) below',
                    dialog_stepper_title_4_l_summary: 'Sign up details',
                    dialog_stepper_title_4_l_summary_1: 'Location',
                    dialog_stepper_title_4_l_summary_2: 'Space',
                    dialog_stepper_title_4_l_summary_3: 'Specialist',
                    dialog_stepper_title_4_l_summary_4: 'Time',
                    dialog_stepper_title_4_l_summary_5: 'Time',
                    "dialog_stepper_checkbox_l": "Yes, send me a reminder email",
                    "dialog_stepper_thnx_title": "Thank you for your registration.",
                    "dialog_stepper_thnx_text": "Thank you for your registration. In your mailbox you will find the confirmation email (tip: also check your spam/unwanted folder to be sure). Nice of you to join this activity!",
                    "dialog_stepper_thnx_activity": "Activity",
                    "dialog_stepper_thnx_date": "Date",
                    "dialog_close_btn": "Close",
                    "dialog_activity_btn": "To activities",
                },
                "changeActivityDialogDialog": {
                    "dialog_title": "Step 1/4",
                    "dialog_title_group_1": "Step 1/3",
                    "dialog_title_group_2": "Step 2/3",
                    "dialog_title_group_3": "Step 3/3",
                    "dialog_text": "Change your registration here for:",
                    "dialog_date_l": "Choose day",
                    "dialog_date_ph_title": "Select day",
                    "dialog_date_ph_text": "Enter date",
                    "dialog_selected_date_l": "Selected days",
                    "dialog_prev_btn": "Previous",
                    "dialog_next_btn": "Continue",
                    "dialog_stepper_title": "Step 2/4",
                    "dialog_stepper_text": "Change your registration here for:",
                    "dialog_stepper_settings_l": "Choose your settings for:",
                    "dialog_stepper_branche_l": "Choose location",
                    "dialog_stepper_branche_ph": "Select a location",
                    "dialog_stepper_specialist_l": "Choose your specialist",
                    "dialog_stepper_specialist_ph": "Select a specialist",
                    "dialog_stepper_title_3": "Step 3/4",
                    "dialog_stepper_title_4": "Step 4/4",
                    "dialog_stepper_title_4_l": "Comments",
                    "dialog_stepper_title_4_text_ph": "Is there anything else you would like us to know? For example, do you have an injury, or do we need to take something else into account? Tell us about it here!",
                    "dialog_stepper_title_4_text": "Your details are safe with us and are only used internally. We do not share your personal information with your employer or others.",
                    "dialog_stepper_title_4_l_invite": "Invite your favorite colleague(s) to participate",
                    "dialog_stepper_title_4_l_email": "Colleagues' email",
                    dialog_stepper_title_4_l_summary: 'Registration details',
                    dialog_stepper_title_4_l_summary_1: 'Location',
                    dialog_stepper_title_4_l_summary_2: 'Space',
                    dialog_stepper_title_4_l_summary_3: 'Specialist',
                    dialog_stepper_title_4_l_summary_4: 'Date',
                    dialog_stepper_title_4_l_summary_5: 'Time',
                    "dialog_stepper_checkbox_l": "Yes, send me a reminder email",
                    "dialog_stepper_thnx_title": "Thank you for your registration.",
                    "dialog_stepper_thnx_text": "You will find the confirmation email in your mailbox (tip: also check the spam/junk folder to be sure). Nice that you are participating in this activity!",
                    "dialog_stepper_thnx_activity": "Activity",
                    "dialog_stepper_thnx_date": "Date",
                    "dialog_close_btn": "Close",
                    "dialog_activity_btn": "To activities",
                },
                "kAvatarEdit": {
                    "avatar_file_l": "Choose file or drag and drop",
                    "avatar_file_drop": "Drop the file here",
                    "avatar_btn_change": "Change",
                    "avatar_btn_cancel": "Cancel",
                    "avatar_btn_save": "Save",
                },
                "kNoPermission": {
                    "perm_title": "No access",
                    "perm_text": "Your account does not have the correct rights for this screen.",
                    "perm_btn": "To Dashboard",
                },
                "kAppSidebar": {
                    "sidebar_item_bedrijfs": "Company page",
                    "sidebar_item_gegevens": "Account details",
                    "sidebar_item_contact": "Contact",
                },
                "kAppTopbar": {
                    "topbar_account_btn": "Create Account",
                },
                "kApp404": {
                    "app_404_title": "Page not found",
                    "app_404_text": "Having trouble? We are happy to help!",
                    "app_404_btn": "Contact",
                },
                "kApp404Activiteiten": {
                    "activities_title": "Page not found.",
                    "activities_title_text": "Check out these activities:",
                    "activities_text": "Having trouble? We are happy to help!",
                    "activities_btn": "Contact",
                },
                "contactcard": {
                    "card_title": "Need help?",
                    "card_contact_btn": "View our FAQ",
                    "card_text": "I am ready to answer all your questions.",
                },
                "deleteConfirmDialog": {
                    "delete_title": "",
                    "delete_text": "After deletion, it is not possible to retrieve this.",
                    "delete_btn_cancel": "No, not at all",
                    "delete_btn_remove": "Delete",
                },
                unsubscribeConfirmDialog: {
                    delete_title: '',
                    text: 'Are you sure you want to unsubscribe from ',
                    delete_btn_cancel: 'No, not at all',
                    delete_btn_remove: 'Yes, I am unsubscribing',
                },
            },

        },






        nl: {
            Bedrijfspagina: 'Bedrijfspagina',

            account_aanmaken: "Inschrijven",
            signup: 'Aanmelden',
            choose_lang: 'Kies je taal',
            emailadres: 'E-mailadres',
            password: 'Wachtwoord',
            lost_password: "Wachtwoord vergeten",

            component: {
                loggedOutOverview: {
                    title: 'Leuk dat je wilt meedoen aan onze activiteiten!',
                    content: 'Ben je nieuw hier? Maak dan eerst een account aan. Ben je hier vaker geweest en heb je al een account? Log dan in en bekijk meteen alle activiteiten.',


                    next_activity: 'Eerstvolgende activiteit(en)',
                },
            },

            views: {
                auth: {
                    login: {
                        inloggen: 'Inloggen',
                        content: 'Welkom terug! Log hier in en ga direct aan de slag.',
                        email_placeholder: 'Vul hier je e-mailadres in',
                        password_placeholder: 'Vul hier je wachtwoord',

                    },
                    newpassword: {
                        expired_token_1: 'De link om je wachtwoord te wijzigen is verlopen.',
                        expired_token_2: 'De link is namelijk maximaal 12 uur geldig.',
                        expired_token_3: 'Probeer het opnieuw.',
                        expired_btn: 'Wachtwoord aanvragen',
                        title: 'Wachtwoord herstellen',
                        content: 'De link voor het wijzigen van je wachtwoord is verlopen. Dit komt omdat de link maximaal 15 minuten geldig is. Probeer het opnieuw.',
                        save_btn: 'Wachtwoord opslaan',
                        back_to_login_btn: 'Terug naar inlogscherm',
                        request_password: 'Wachtwoord aanvragen',
                        choose_pass: 'Kies een nieuwe wachtwoord',
                        pass_l: 'Wachtwoord',
                        pass_ph: 'Vul hier je nieuwe wachtwoord in',
                        pass_repeat_l: 'Wachtwoord',
                        pass_repeat_ph: 'Herhaal wachtwoord',
                        pass_check_title: 'Je wachtwoord moet:',
                        pass_check_length: 'Minimaal 8 tekens lang zijn',
                        pass_check_uppercase: 'Minimaal 1 hoofdletter',
                        pass_check_lowercase: 'Minimaal 1 kleine letter',
                        pass_check_number: 'Minimaal 1 cijfer',
                        pass_check_car: 'Minimaal 1 speciaal teken (@,!,-,#,$)',
                        pass_check_same: 'Wachtwoorden moeten gelijk zijn',
                    },
                    registerconfirm: {
                        termsandconditions: 'Ik ga akkoord met de',
                        generalconditions: 'algemene voorwaarden',
                        expired_title: 'De link is ongeldig.',
                        expired_text1: 'De link voor het inschrijven van je account is ongeldig.',
                        expired_text2: 'Probeer het opnieuw.',
                        title: 'Verifieer je account',
                        content: 'Welkom! Maak een account aan met de gegevens van je werk.',
                        name_l: 'Naam',
                        "email_l": "Email",
                        "email_ph": "Vul hier je email",
                        name_ph: 'Vul hier je voornaam',
                        lastname_l: 'Achternaam',
                        lastname_ph: 'Vul hier je achternaam',
                        password_l: 'Wachtwoord',
                        password_ph: 'Kies een wachtwoord',
                        password_repeat_l: 'Herhaal wachtwoord',
                        password_repeat_ph: 'Herhaal wachtwoord',
                        terms: 'Ik ga akkoord met de algemene voorwaarden',
                        verify_acc: 'Verifieer account',
                        privacy_settings: 'Privacy-instellingen',
                        check1: 'Ik stem ermee in dat mijn accountgegevens mogen worden gebruikt door de Fitte Medewerker. Dit stelt De Fitte Medewerker in staat om de best mogelijke ondersteuning te bieden.',
                        check2: 'Ik geef toestemming aan de Fitte Medewerker om contact met mij op te nemen in geval van directe vragen. Dit helpt ons om snel en efficiënt te reageren op uw behoeften.',
                        submit_btn: 'Account aanmaken',
                        sub_content: 'Je gegevens zijn veilig bij ons en worden alleen intern gebruikt. We delen je persoonlijke informatie niet met je werkgever of anderen.',

                    },
                    resetpassword: {
                        content: 'Voer je e-mail en wij zullen een link sturen om jouw wachtwoord opnieuw in te stellen.',
                        email_placeholder: 'Vul hier je e-mailadres in',
                        submit_btn: 'Verzenden',
                        back_to_login_btn: 'Terug naar inlogscherm',
                        request_send_title: 'We hebben je een mail gestuurd!',
                        request_send_content1: 'Een mail met een unieke link erin om een nieuw wachtwoord aan te maken is verstuurd naar',
                        request_send_content2: 'De link in de mail is maximaal 15 minuten geldig. Controleer eventueel je spamfolder voor je het opnieuw probeert.',
                        toast: {
                            no_account_found: 'Er is geen account op dit e-mailadres!',
                            error_changing_pass: "Er ging iets fout bij het aanvragen van een nieuw wachtwoord!",
                        },
                    },
                    register: {
                        title: 'Account maken',
                        content: 'Ontgrendel exclusieve activiteiten door nu te registreren',
                        name_l: 'Naam',
                        name_ph: 'Vul hier je voornaam',
                        lastname_l: 'Achternaam',
                        lastname_ph: 'Vul hier je achternaam',
                        email_l: 'E-mailadres',
                        email_ph: 'Vul hier je e-mailadres',
                        terms: 'Ik ga akkoord met de Algemene voorwaarden',
                        submit_btn: ' Account aanmaken',
                        sub_content: 'Je gegevens zijn veilig bij ons en worden alleen intern gebruikt. We delen je persoonlijke informatie niet met je werkgever of anderen.',
                    },
                },
                account: {
                    overview: {
                        page_title: 'Gegevens bewerken',
                        preferences_edit_title: 'Voorkeuren bewerken',

                        language: 'Taal',
                        firstname_l: 'Voornaam',
                        firstname_ph: 'Voornaam',
                        surname_l: 'Achternaam',
                        surname_ph: 'Achternaam',
                        email_l: 'E-mailadres',
                        email_ph: 'E-mailadres',
                        password_title: 'Wachtwoord veranderen',
                        current_password_l: 'Huidige wachtwoord',
                        current_password_ph: 'Vul hier je wachtwoord in',
                        new_password_l: 'Nieuwe wachtwoord',
                        new_password_ph: 'Vul hier je nieuwe wachtwoord in',
                        repeat_new_password_l: 'Herhaal nieuwe wachtwoord',
                        repeat_new_password_ph: 'Herhaal nieuwe wachtwoord',
                        cancel_btn: 'Annuleren',
                        save_btn: 'Opslaan',
                        profile_pic_title: 'Profiel foto',
                        upload_pic_l: 'Upload afbeelding (JPEG, PNG, WEBP), max 2MB',
                        user_data_title: 'Basisgegevens',
                        change_btn_l: 'Vervangen',
                        edit_btn_l: 'Bewerk je gegevens',
                        name_title: 'Naam',
                        email_title: 'E-mailadres',
                        password_l: 'Wachtwoord',
                        notification_title: 'Notificaties',
                        preferences_title: 'Voorkeuren',
                        email_reminders_title: 'Stuur mij een herinnering',
                        email_review_title: 'Ik wil een review plaatsen na afloop',
                        email_lastspots_title: 'Stuur mij een laatste plekken herinnnering',

                    },
                    template: {
                        toast_settings_message: 'Notificatie instellingen succesvol aangepast.',
                        toast_error_401: 'U heeft geen toegang.',
                        toast_error_403: 'Inactieve gebruiker!',
                        toast_error_default: 'Er is iets misgegaan.',
                        toast_succes_user_edit: 'Gebruiker succesvol bewerkt',
                        toast_error_user_login_401: 'Onjuist e-mailadres en/of wachtwoord!',
                        toast_error_user_login_403: 'Inactieve gebruiker!',
                        toast_error_user_login_default: 'Er is iets fout gegaan met het inloggen',
                        toast_changepass_error_email: 'Er is geen account op dit e-mailadres!',
                        toast_changepass_error_password: 'Er ging iets fout bij het aanvragen van een nieuw wachtwoord!',
                    },
                },
                contact: {
                    overview: {
                        page_title: 'Veelgestelde vragen en contact',
                        page_text: 'Is alles duidelijk? Super! Heb je toch nog vragen? Kijk eerst of jouw vraag in deze lijst staat en krijg snel een antwoord. Niet gevonden wat je zocht? Neem dan contact met ons op.',
                    },
                },
                dashboard: {
                    page_title: 'Vandaag is een goede dag om te glimlachen, vergeet dat niet! 😁',
                    company_activity_title: 'Geplande activiteiten',
                    enroll_title: 'Eerstvolgende activiteit: ',
                    company_activity_signups: 'Aanmeldingen',
                    company_activity_page_l: 'Naar activiteitenpagina',
                    company_signups_l: 'Aanmeldingen bekijken',
                    company_download_signups_l: 'Download aanmeldingen',
                    company_activity_text: 'Op dit moment zijn er geen activiteiten gepland.',
                    employee_activity_title: 'Je kunt je nog aanmelden',
                    employee_activity_text: 'Er zijn op dit moment geen activiteiten beschikbaar om je voor aan te melden.',
                    employee_signup_activity_title: 'Jij hebt je aangemeld voor',
                    employee_signup_activity_text: 'Er zijn geen activiteiten waarvoor je je hebt aangemeld.',
                    activity_time_l: 'Ik wil iets aanpassen',
                    activity_participants_l: 'Wie doen er mee?',
                    activity_signout_l: 'Ik wil mij afmelden',
                    activity_no_signup_l: 'Nog geen aanmeldingen gevonden. Ontdek en meld je aan voor boeiende activiteiten!',
                    activity_signup_l: 'Naar Activiteiten',
                    activity_greeting_morning: 'Goedemorgen',
                    activity_greeting_afternoon: 'Goedemiddag',
                    activity_greeting_evening: 'Goedenavond',
                },
                template404: {
                    topbar_title: 'Deze pagina bestaat niet',
                },
                template: {
                    enroll_update_succes_edited: 'Succesvol aangepast',
                    enroll_update_cancel_succes: 'Succesvol afgemeld',
                    enroll_update_cancel_text: 'De afmeldperiode is al afgelopen!',
                    enroll_update_cancel_error: 'Er is iets fout gegaan met het afmelden voor de activiteit',
                },
                companypages: {
                    template: {
                        register_enroll_succes: 'Succesvol aangemeld',
                        register_enroll_error: 'Er is iets misgegaan met aanmelden.',
                        register_enroll_error_catch: 'Er is iets misgegaan met aanmelden.',
                    },
                    overview: {
                        overview_btn_register: 'Bekijk',
                        overview_pick_lang: 'Kies je taal',
                        overview_activity_title: 'Eerstvolgende activiteit(en)',
                        overview_btn_regsiter: 'Aanmelden',
                        overview_other_activity_title: 'Alle activiteit(en)',
                    },
                },
                activities: {
                    template: {
                        register_enroll_succes: 'Succesvol aangemeld',
                        register_enroll_error: 'Er is iets misgegaan met aanmelden.',
                        register_enroll_error_catch: 'Er is iets misgegaan met aanmelden.',
                    },
                    registrations: {
                        registrations_btn_download: 'Download Aanmeldingen',
                        registrations_title: 'Lachworkshop: zet je lachspieren aan het werk',
                        registrations_company_l: 'Bedrijfspagina kiezen',
                        registrations_location_l: 'Vestiging(en) kiezen',
                        registrations_deal_l: 'Deal',
                        registrations_company_ph: 'Selecteer een bedrijfspagina',
                        registrations_location_ph: 'Kies gewenste vestiging',
                        registrations_deal_ph: 'Selecteer een deal',
                        registrations_btn_cancel: 'Annuleren',
                        registrations_btn_connect: 'koppelen',
                    },
                    view: {
                        view_btn_register: 'Aanmelden',
                        view_text_place_avail: 'Er is een beperkt aantal plekken, ',
                        view_text_place_avail_2: 'wees er snel bij.',
                        view_l_date: 'Datum',
                        view_l_date_till: 'tot',
                        view_l_date_time: 'Tijd',
                        view_l_location: 'Locatie(s)',
                        view_l_specialists: 'Specialist(en)',
                        view_l_watch_out: 'LET OP:',
                        view_text_watch_out: 'Wil je iets wijzigen in je aanmelding? Kies dan voor Aanpassen. Wil je je afmelden? Jammer! We vragen je dit minimaal ',
                        view_text_watch_out_2: 'uur van tevoren te doen.',
                        view_l_need: 'Benodigheden',
                        view_l_expected: 'Wat kan je verwachten',
                    },
                },
            },



            components: {

                changeActivityDialog: {
                    dialog_title: 'Wat wil je doen?',
                    dialog_text: 'Voor afmeldingen of wijzingen vragen wij u vriendelijk om dit minimaal 48 uur van tevoren te doen.',
                    dialog_btn_cancel: 'Afmelden',
                    dialog_btn_edit: 'Aanpassen',
                },
                changeTimeDialog: {
                    dialog_title: 'Stap 1/2',
                    dialog_text: 'Leuk dat jij je aanmeldt voor:',
                    dialog_date_l: 'Dag(en) kiezen',
                    dialog_date_ph_title: 'Dag selecteren',
                    dialog_date_ph_text: 'Datum invoeren',
                    dialog_selected_date_l: 'Geselecteerde dag',
                    dialog_cancel_btn: 'Annuleren',
                    dialog_next_btn: 'Ga verder',
                    dialog_stepper_title: 'Stap 2/2',
                    dialog_stepper_text: 'Leuk dat jij je aanmeldt voor:',

                    dialog_stepper_l: 'Beschikbare tijden',
                    dialog_prev_btn: 'Stap terug',
                    dialog_save_btn: 'Wijziging opslaan',
                },

                registerActivityDialog: {

                    dialog_title: 'Stap 1/4',
                    dialog_text: 'Leuk dat jij je aanmeldt voor:',
                    dialog_date_l: 'Dag(en) kiezen',
                    dialog_date_ph_title: 'Dag selecteren',
                    dialog_date_ph_text: 'Datum invoeren',
                    dialog_selected_date_l: 'Geselecteerde dag(en)',
                    dialog_prev_btn: 'Stap terug',
                    dialog_next_btn: 'Ga verder',
                    dialog_title_group_1: "Stap 1/3",
                    dialog_title_group_2: "Stap 2/3",
                    dialog_title_group_3: "Stap 3/3",
                    dialog_stepper_title: 'Stap 2/4',
                    dialog_stepper_text: 'Leuk dat jij je aanmeldt voor:',
                    dialog_stepper_settings_l: 'Kies je instellingen voor:',
                    dialog_stepper_branche_l: 'Kies je vestiging',
                    dialog_stepper_branche_ph: 'Selecteer een vestiging',
                    dialog_stepper_specialist_l: 'Kies je specialist',
                    dialog_stepper_specialist_ph: 'Selecteer een specialist',
                    dialog_stepper_title_3: 'Stap 3/4',
                    dialog_stepper_title_4: 'Stap 4/4',
                    dialog_stepper_title_4_l: 'Opmerkingen',
                    dialog_stepper_title_4_text_ph: 'Wil je ons nog iets laten weten? Heb je bijvoorbeeld een blessure, of moeten we ergens anders rekening mee houden? Vertel het hier!',
                    dialog_stepper_title_4_text: 'Je gegevens zijn veilig bij ons en worden alleen intern gebruikt. We delen je persoonlijke informatie niet met je werkgever of anderen.',
                    dialog_stepper_title_4_l_summary: 'Aanmeld gegevens',
                    dialog_stepper_title_4_l_summary_1: 'Vestiging',
                    dialog_stepper_title_4_l_summary_2: 'Ruimte',
                    dialog_stepper_title_4_l_summary_3: 'Specialist',
                    dialog_stepper_title_4_l_summary_4: 'Datum',
                    dialog_stepper_title_4_l_summary_5: 'Tijd',
                    dialog_date_choose: 'Selecteer hieronder je gewenste dag(en)',
                    dialog_stepper_title_4_l_invite: 'Nodig je favoriete collega(‘s) uit om mee te doen',
                    dialog_stepper_title_4_l_email: 'Collegas e-mail',
                    dialog_stepper_checkbox_l: 'Ja, stuur mij een herinneringsmail',
                    dialog_stepper_thnx_title: 'Gelukt!',
                    dialog_stepper_thnx_text: 'Bedankt voor je aanmelding. In je mailbox vind je de bevestigingsmail (tip: check voor de zekerheid ook de map spam/ongewenst). Leuk dat je meedoet aan deze activiteit!',
                    dialog_stepper_thnx_activity: 'Activiteit',
                    dialog_stepper_thnx_date: 'Datum',
                    dialog_close_btn: 'Sluiten',
                    dialog_activity_btn: 'Naar activiteiten',
                },
                changeActivityDialogDialog: {
                    dialog_title: 'Stap 1/3',
                    dialog_text: 'Wijzig hier je aanmelding voor:',
                    dialog_date_l: 'Dag(en) kiezen',
                    dialog_date_ph_title: 'Dag selecteren',
                    dialog_date_ph_text: 'Datum invoeren',
                    dialog_selected_date_l: 'Geselecteerde dag(en)',
                    dialog_prev_btn: 'Stap terug',
                    dialog_next_btn: 'Ga verder',
                    dialog_title_group_1: "Stap 1/3",
                    dialog_title_group_2: "Stap 2/3",
                    dialog_title_group_3: "Stap 3/3",
                    dialog_stepper_title: 'Stap 2/4',
                    dialog_stepper_text: 'Wijzig hier je aanmelding voor:',
                    dialog_stepper_settings_l: 'Kies je instellingen voor:',
                    dialog_stepper_branche_l: 'Kies je vestiging',
                    dialog_stepper_branche_ph: 'Selecteer een vestiging',
                    dialog_stepper_specialist_l: 'Kies je specialist',
                    dialog_stepper_specialist_ph: 'Selecteer een specialist',
                    dialog_stepper_title_3: 'Stap 3/4',
                    dialog_stepper_title_4: 'Stap 4/4',
                    dialog_stepper_title_4_l: 'Opmerkingen',
                    dialog_stepper_title_4_text_ph: 'Wil je ons nog iets laten weten? Heb je bijvoorbeeld een blessure, of moeten we ergens anders rekening mee houden? Vertel het hier!',
                    dialog_stepper_title_4_text: 'Je gegevens zijn veilig bij ons en worden alleen intern gebruikt. We delen je persoonlijke informatie niet met je werkgever of anderen.',
                    dialog_stepper_title_4_l_summary: 'Aanpassing gegevens',
                    dialog_stepper_title_4_l_summary_1: 'Vestiging',
                    dialog_stepper_title_4_l_summary_2: 'Ruimte',
                    dialog_stepper_title_4_l_summary_3: 'Specialist',
                    dialog_stepper_title_4_l_summary_4: 'Datum',
                    dialog_stepper_title_4_l_summary_5: 'Tijd',

                    dialog_stepper_title_4_l_invite: 'Nodig je favoriete collega(‘s) uit om mee te doen',
                    dialog_stepper_title_4_l_email: 'Collegas e-mail',
                    dialog_stepper_checkbox_l: 'Ja, stuur mij een herinneringsmail',
                    dialog_stepper_thnx_title: 'Gelukt!',
                    dialog_stepper_thnx_text: 'Bedankt voor je aanmelding. In je mailbox vind je de bevestigingsmail (tip: check voor de zekerheid ook de map spam/ongewenst). Leuk dat je meedoet aan deze activiteit!',
                    dialog_stepper_thnx_activity: 'Activiteit',
                    dialog_stepper_thnx_date: 'Datum',
                    dialog_close_btn: 'Sluiten',
                    dialog_activity_btn: 'Naar activiteiten',
                },
                kAvatarEdit: {
                    avatar_file_l: 'Kies bestand of sleep en plaats',
                    avatar_file_drop: 'Drop het bestand hier',
                    avatar_btn_change: 'Wijzigen',
                    avatar_btn_cancel: 'Annuleren',
                    avatar_btn_save: 'Opslaan',
                },

                kNoPermission: {
                    perm_title: 'Geen toegang',
                    perm_text: 'Je account heeft niet de juiste rechten voor dit scherm.',
                    perm_btn: 'Naar Dashboard',
                },
                kAppSidebar: {
                    sidebar_item_bedrijfs: 'Bedrijfspagina',
                    sidebar_item_gegevens: 'Accountgegevens',
                    sidebar_item_contact: 'Contact',
                },

                kAppTopbar: {
                    topbar_account_btn: 'Account Aanmaken',
                },

                kApp404: {
                    app_404_title: 'Pagina niet gevonden',
                    app_404_text: 'Kom je er niet uit? Wij helpen je graag verder!',
                    app_404_btn: 'Contact',
                },

                kApp404Activiteiten: {
                    activities_title: 'Pagina niet gevonden.',
                    activities_title_text: 'Bekijk eens deze activiteiten:',
                    activities_text: 'Kom je er niet uit? Wij helpen je graag verder!',
                    activities_btn: 'Contact',
                },

                contactcard: {
                    card_title: 'Hulp nodig?',
                    card_contact_btn: 'Bekijk onze FAQ',
                    card_text: 'Ik zit klaar om al je vragen te beantwoorden.',
                },

                deleteConfirmDialog: {
                    delete_title: '',
                    delete_text: 'Na het verwijderen is het niet meer mogelijk om dit terug te halen.',
                    delete_btn_cancel: 'Nee, toch niet',
                    delete_btn_remove: 'Verwijderen',
                },
                unsubscribeConfirmDialog: {
                    delete_title: '',
                    text: 'Weet je zeker dat je je wilt afmelden voor de ',
                    delete_btn_cancel: 'Nee, toch niet',
                    delete_btn_remove: 'Ja, ik meld mij af',
                },
            },

        }

    },
});

export default i18n;